import CircularProgress from '@mui/material/CircularProgress'
import { Dropin as DropinType } from 'braintree-web-drop-in'
import DropIn from 'braintree-web-drop-in-react'
import { Button, P } from 'components'
import {
  BraintreeQueryKeys,
  useBraintreeClientToken,
  useCreateBraintreeSubscription,
} from 'contexts/braintree/hooks'
import { useClosePaymentModal } from 'contexts/braintree/PaymentModalProvider'
import { PlanWithConfig } from 'contexts/braintree/types'
import { useShowDialog } from 'contexts/dialog/Controller'
import { CSSProperties, useCallback, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Path } from 'router/constants'
import { StorageItems, useStorage } from 'utils/storage'

type BraintreeDropInProps = {
  plan: PlanWithConfig
  style?: CSSProperties
}

export default function BraintreeDropIn({ plan, style }: BraintreeDropInProps) {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const showDialog = useShowDialog()
  const { id, price } = plan
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  const { braintreeClientToken } = useBraintreeClientToken()
  const { createSubscription } = useCreateBraintreeSubscription()
  const closePaymentModal = useClosePaymentModal()

  const [braintreeInstance, setBraintreeInstance] = useState<DropinType>()

  const handleSubmit = useCallback(() => {
    braintreeInstance?.requestPaymentMethod((error, payload) => {
      if (error) {
        console.error(error)
      } else if (!token) {
        console.error('no access token')
      } else {
        setLoading(true)
        createSubscription(
          {
            plan_id: id,
            payment_method_nonce: payload.nonce,
            accessToken: token,
            trial_duration: plan.daysTrial,
            trial_period: plan.daysTrial > 0,
          },
          {
            onSuccess: () => {
              closePaymentModal()
              queryClient.setQueryData(
                BraintreeQueryKeys.SUBSCRIPTIONS,
                () => ({
                  success: true,
                  subscription: {
                    createdAt: new Date(),
                    planId: id,
                    updatedAt: new Date(),
                    status: 'Active',
                  },
                }),
              )
              showDialog({
                title: 'Success',
                subtitle: 'Congratulations—your payment was successful!',
                option2Text: null,
              })
              navigate(`/${Path.ATHLETE}/${Path.INTAKE_FORM}`)
            },
            onSettled: () => setLoading(false),
          },
        )
      }
    })
  }, [
    braintreeInstance,
    closePaymentModal,
    createSubscription,
    id,
    navigate,
    queryClient,
    showDialog,
    token,
    plan.daysTrial,
  ])

  return (
    <div>
      {!braintreeClientToken ? (
        <CircularProgress />
      ) : (
        <>
          {!braintreeInstance ? <CircularProgress /> : null}
          <div style={{ display: !braintreeInstance ? 'none' : 'block' }}>
            <P style={style ?? {}}>Amount: {price}</P>
            <DropIn
              options={{ authorization: braintreeClientToken }}
              onInstance={(instance) => setBraintreeInstance(instance)}
            />
            <Button
              variant='contained'
              fullWidth
              style={{ color: 'white' }}
              disabled={!braintreeInstance}
              onClick={handleSubmit}>
              {loading ? <CircularProgress color='secondary' /> : 'Pay'}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
