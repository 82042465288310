/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Plan, Subscription } from 'braintree'
import { apiFetch } from 'contexts/api/functions'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ApiPath, BraintreePlanId, BRAINTREE_PLAN_DATA } from 'utils/config'
import { StorageItems, useStorage } from 'utils/storage'
import { PlanWithConfig } from './types'
import { useEffect } from 'react'

export enum BraintreeQueryKeys {
  CLIENT_TOKEN = 'BRAINTREE_CLIENT_TOKEN',
  PLANS = 'BRAINTREE_PLANS',
  SUBSCRIPTIONS = 'BRAINTREE_SUBSCRIPTIONS',
}
type BraintreeClientTokenQueryKey = BraintreeQueryKeys.CLIENT_TOKEN
type BraintreePlansQueryKey = BraintreeQueryKeys.PLANS
type BraintreeSubscriptionsQueryKey = BraintreeQueryKeys.SUBSCRIPTIONS
export type SubscriptionWithPlan = Subscription & { plan: Plan | undefined }
type GetSubscriptionsResponse = {
  success: boolean
  subscription?: Subscription
}
type UpdateSubscriptionResponse = unknown | void
type CreateSubscriptionParams = {
  plan_id: string
  payment_method_nonce: string
  accessToken: string
  trial_duration: number
  trial_period: boolean
}
// type CancelSubscriptionParams = {
//   accessToken: string
// }

export const useBraintreeClientToken = () => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const { data: braintreeClientToken, ...rest } = useQuery<
    string,
    Error,
    string,
    BraintreeClientTokenQueryKey
  >(BraintreeQueryKeys.CLIENT_TOKEN, () => getBraintreeClientToken(), {
    enabled: !!token,
  })
  return { braintreeClientToken, ...rest }
}

const getBraintreeClientToken = async () => {
  const clientToken = await apiFetch<string>(ApiPath.PAYMENT_CLIENT_TOKEN)
  return clientToken
}

export const useBraintreePlans = () => {
  const { data: braintreePlans, ...rest } = useQuery<
    PlanWithConfig[],
    Error,
    PlanWithConfig[],
    BraintreePlansQueryKey
  >(BraintreeQueryKeys.PLANS, () => getBraintreePlans(), {
    enabled: true,
  })

  return { braintreePlans, ...rest }
}

const getBraintreePlans = async (): Promise<PlanWithConfig[]> => {
  const plans = await apiFetch<{ [id: string]: Plan }>(ApiPath.PAYMENT_PLANS)
  const sortedPlansWithFavor = Object.values(plans ?? {})
    .filter((a) => a.id !== BraintreePlanId.PROD_RESUB)
    .sort((a, b) =>
      a.numberOfBillingCycles > b.numberOfBillingCycles ? 1 : -1,
    )
    .map((plan) => ({
      ...plan,
      ...BRAINTREE_PLAN_DATA[plan.id as BraintreePlanId],
    }))

  console.log('sortedPlansWithFavor', sortedPlansWithFavor)
  return sortedPlansWithFavor
}

export const useCreateBraintreeSubscription = () => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const { mutate: createSubscription, ...rest } = useMutation<
    UpdateSubscriptionResponse,
    Error,
    CreateSubscriptionParams
  >((params) => {
    if (token) {
      return postBraintreeSubscription(
        params.plan_id,
        params.payment_method_nonce,
        params.trial_duration,
        params.trial_period,
      )
    } else {
      throw new Error('No access token')
    }
  })

  return { createSubscription, ...rest }
}

const postBraintreeSubscription = async (
  plan_id: string,
  payment_method_nonce: string,
  trial_duration: number,
  trial_period: boolean,
) => {
  const body = {
    planId: plan_id,
    payment_method_nonce,
    trial_duration,
    trial_period,
    trial_duration_unit: 'day',
  }
  // console.log('postBraintreeSubscription data', data)
  const response = await apiFetch(ApiPath.PAYMENT_CREATE_SUBSCRIPTION, {
    method: 'POST',
    body,
  })
  return response
  //https://developer.paypal.com/braintree/docs/reference/request/subscription/create#payment_method_token
  // must use the cusotmer_id to use a payment nonce, or I can use a payment method token from
}

export const useBraintreeSubscription = () => {
  const { braintreePlans } = useBraintreePlans()
  const { data, refetch, ...rest } = useQuery<
    GetSubscriptionsResponse,
    Error,
    GetSubscriptionsResponse,
    BraintreeSubscriptionsQueryKey
  >(BraintreeQueryKeys.SUBSCRIPTIONS, () => getBraintreeSubscription(), {
    enabled: !!braintreePlans,
  })

  const braintreeSubscription =
    data?.success && data?.subscription?.status === 'Active'
      ? data.subscription
      : null

  const plan =
    braintreeSubscription && braintreePlans
      ? braintreePlans.find((plan) => plan.id === braintreeSubscription.planId)
      : null

  // console.log('useBraintreeSubscriptions', data, braintreeSubscription, rest)

  useEffect(() => {
    refetch()
  }, [refetch])

  return { braintreeSubscription, plan, ...rest }
}

const getBraintreeSubscription =
  async (): Promise<GetSubscriptionsResponse> => {
    const subscriptions = await apiFetch<GetSubscriptionsResponse>(
      ApiPath.PAYMENT_GET_SUBSCRIPTION,
    )
    // console.log('getting Braintree subscriptions')
    return subscriptions
  }

export const useCancelBraintreeSubscription = () => {
  const token = useStorage(StorageItems.ACCESS_TOKEN)
  const queryClient = useQueryClient()
  const { mutate: cancelSubscription, ...rest } = useMutation<
    UpdateSubscriptionResponse,
    Error
  >(
    async () => {
      if (token) {
        postCancelBraintreeSubscription()
      } else {
        throw new Error('No access token')
      }
    },
    {
      onSuccess: () => {
        setTimeout(
          () => queryClient.invalidateQueries(BraintreeQueryKeys.SUBSCRIPTIONS),
          1000,
        )
      },
    },
  )

  return { cancelSubscription, ...rest }
}

const postCancelBraintreeSubscription = async () => {
  const response = await apiFetch(ApiPath.PAYMENT_CANCEL_SUBSCRIPTION, {
    method: 'POST',
  })
  return response
}
