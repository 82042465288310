import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import {
  GridCellParams,
  GridColumns,
  GridRowId,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { useUsersByRole } from 'contexts/api/hooks'
import { OverrideOptions, Role, UserByRole } from 'contexts/api/types'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useUiDispatch } from './UiProvider'

type Row = UserByRole
const TOTAL_QUESTIONS = 135

export const useDataGrid = () => {
  const { users: data, isLoading } = useUsersByRole(Role.USER)

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>()
  const { setMode } = useUiDispatch()
  // const { mutateUpdate } = useMutate()

  useEffect(() => {
    if (selectionModel?.length) {
      setMode('selecting')
    } else {
      setMode('idle')
    }
  }, [selectionModel, setMode])

  const coachColumns = useMemo(
    () =>
      Object.entries(TITLES).map(([field, title]) => ({
        field,
        headerName: title,
        width: 120,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          row.relationships
            ?.filter((r) => r.category === field)
            .map((r) => r?.name),
        renderCell: ({ row }: GridCellParams<boolean, Row>) =>
          row.relationships
            ?.filter((r) => r.category === field)
            .map((r) => (
              <Chip
                key={`${r.id}-${r.category}`}
                label={r.name}
                size='small'
                deleteIcon={undefined}
                style={{ margin: 3 }}
                onClick={(event) => {
                  event.stopPropagation()
                }}
              />
            )),
      })),
    [],
  )

  const columns: GridColumns = useMemo(
    () => [
      { field: GridColumnsFields.ID, headerName: 'ID', width: 100 },
      {
        field: GridColumnsFields.NAME,
        headerName: 'Name',
        width: 120,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          `${row.name}`,
      },
      {
        field: GridColumnsFields.EMAIL,
        headerName: 'Email',
        width: 240,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) =>
          row.email,
      },
      // {
      //   field: GridColumnsFields.PHONE,
      //   headerName: 'Phone',
      //   width: 120,
      //   valueGetter: ({ row }: GridValueGetterParams<unknown, User>) =>
      //     row.phone,
      // },
      {
        field: GridColumnsFields.QUESTIONS_ANSWERED,
        headerName: 'Questions Answered',
        width: 240,
        valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) => {
          const responseCount = Object.keys(row.formResponses ?? {}).length
          return `${responseCount} out of ${TOTAL_QUESTIONS}`
        },
      },
      // {
      //   field: GridColumnsFields.SUBSCRIPTION_STATUS,
      //   headerName: 'Subscription Status',
      //   width: 240,
      //   valueGetter: ({ row }: GridValueGetterParams<unknown, Row>) => {
      //     if (!row.subscriptionStatus) {
      //       return 'No Plan'
      //     }
      //     return row.subscriptionStatus
      //   },
      // },
      {
        field: GridColumnsFields.CREATED_AT,
        headerName: 'Created',
        width: 120,
        renderCell: ({ row }: GridCellParams<boolean, Row>) => (
          <Tooltip
            title={DateTime.fromISO(row.createdAt)
              .setZone('UTC', { keepLocalTime: true })
              .toISODate()}>
            <div>
              {DateTime.fromISO(row.createdAt)
                //TODO Fix time zones
                .setZone('UTC', { keepLocalTime: true })
                .toRelative()}
            </div>
          </Tooltip>
        ),
      },
      {
        field: GridColumnsFields.ROLE,
        headerName: 'Role',
        width: 70,
        valueGetter: () => 'User',
      },
      {
        field: GridColumnsFields.MANUAL_OVERRIDE,
        headerName: 'Override',
        width: 100,
        renderCell: ({ row }: GridCellParams<boolean, Row>) => (
          <div className=''>
            {row.manualOverride === OverrideOptions.NO_MANUAL_OVERRIDE
              ? ''
              : row.manualOverride === OverrideOptions.PROVIDE_SUBSCRIPTION
              ? 'Y'
              : 'N'}
          </div>
        ),
      },
      // {
      //   field: GridColumnsFields.FORM_RESPONSES,
      //   headerName: 'Form Responses',
      //   width: 120,
      //   valueGetter: ({ row }: GridValueGetterParams<unknown, User>) =>
      //     Object.values(row.formResponses ?? {}).filter(isTruthy).length,
      // },
      ...coachColumns,
    ],
    [coachColumns],
  )

  return {
    columns,
    rows: data,
    selectionModel,
    setSelectionModel,
    isLoading,
  }
}

export enum GridColumnsFields {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  PHONE = 'phone',
  ROLE = 'role',
  MANUAL_OVERRIDE = 'manualOverride',
  QUESTIONS_ANSWERED = 'questionsAnswered',
  SUBSCRIPTION_STATUS = 'subscriptionStatus',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  FORM_RESPONSES = 'formResponses',
  TRAINING_COACH = 'training', // MUST MATCH THE CATEGORY
  NUTRITION_COACH = 'nutrition',
  REST_COACH = 'rest',
  ONE_PERCENT_COACH = 'one-percent',
  INTERNAL_GAME_COACH = 'internal-game',
}

const TITLES: { [column in GridColumnsFields]?: string } = {
  [GridColumnsFields.TRAINING_COACH]: 'Training coach',
  [GridColumnsFields.NUTRITION_COACH]: 'Nutrition coach',
  [GridColumnsFields.REST_COACH]: 'Sleep coach',
  [GridColumnsFields.ONE_PERCENT_COACH]: '1% coach',
  [GridColumnsFields.INTERNAL_GAME_COACH]: 'Internal coach',
}
