import {
  GetFeedResponse,
  GetRelationshipsById,
  GetUserByIdResponse,
  GetUserByRoleResponse,
  GetVideoResponse,
  GetWaiverResponse,
  Role,
} from 'contexts/api/types'
import { PlanConfig } from 'contexts/braintree/types'
import { UserId } from 'contexts/ui/Controller'
import { Relationships } from 'router/constants'
import { IS_LOCAL_DEVELOPMENT } from './constants'

export const enum QueryKeyKey {
  USER_BASE = 'user',
  VIEW_USER = 'user-view',
  DIALOG_USER = 'user-dialog',
  SELF_USER = 'user-self',
  USERS_LIST = 'users',
  RELATIONSHIPS = 'relationships',
  FEED = 'feed',
  VIDEO = 'video',
  WAIVER = 'waiver',
}

export type FeedQueryKey = [QueryKeyKey.FEED, UserId | undefined, Relationships]
export type SelfUserQueryKey = QueryKeyKey.SELF_USER
export type UsersByRoleQueryKey = [QueryKeyKey.USERS_LIST, Role]
export type RelationshipsQueryKey = [
  QueryKeyKey.RELATIONSHIPS,
  UserId | undefined,
]
export type ViewUserQueryKey = [QueryKeyKey.VIEW_USER, UserId | undefined]
export type DialogUserQueryKey = [QueryKeyKey.DIALOG_USER, UserId | undefined]
export type VideoQueryKey = [QueryKeyKey.VIDEO, true | undefined]

export type QueryDataTypes = {
  [QueryKeyKey.USER_BASE]: GetUserByIdResponse | undefined
  [QueryKeyKey.VIEW_USER]: GetUserByIdResponse | undefined
  [QueryKeyKey.DIALOG_USER]: GetUserByIdResponse | undefined
  [QueryKeyKey.SELF_USER]: GetUserByIdResponse | undefined
  [QueryKeyKey.USERS_LIST]: GetUserByRoleResponse | undefined
  [QueryKeyKey.RELATIONSHIPS]: GetRelationshipsById | undefined
  [QueryKeyKey.FEED]: GetFeedResponse | undefined
  [QueryKeyKey.VIDEO]: GetVideoResponse | undefined
  [QueryKeyKey.WAIVER]: GetWaiverResponse | undefined
}

export type QueryKeyTypes = {
  [QueryKeyKey.USER_BASE]: []
  [QueryKeyKey.VIEW_USER]: [QueryKeyKey.VIEW_USER, UserId | undefined]
  [QueryKeyKey.DIALOG_USER]: [QueryKeyKey.DIALOG_USER, UserId | undefined]
  [QueryKeyKey.SELF_USER]: QueryKeyKey.SELF_USER
  [QueryKeyKey.USERS_LIST]: [QueryKeyKey.USERS_LIST, Role]
  [QueryKeyKey.RELATIONSHIPS]: [QueryKeyKey.RELATIONSHIPS, UserId | undefined]
  [QueryKeyKey.FEED]: [QueryKeyKey.FEED, UserId, Relationships]
  [QueryKeyKey.VIDEO]: [QueryKeyKey.VIDEO, UserId]
  [QueryKeyKey.WAIVER]: [QueryKeyKey.WAIVER, UserId]
}

export enum ApiPath {
  LOGIN = '/auth/login',
  LOGOUT = '/auth/logout',
  START_RESET_PASSWORD = '/auth/startResetPassword',
  FINISH_RESET_PASSWORD = '/auth/finishResetPassword',
  SIGNUP = '/auth/signup',
  REFRESH = '/auth/refresh',
  VERIFY = '/auth/verify',

  USER_GET_SELF = '/users',
  USER_GET_BY_ID = '/users',
  USER_GET_BY_ROLE = '/users/role',
  USER_PATCH = '/users',
  USER_PATCH_BY_ID = '/users',
  USER_DELETE = '/users',
  USER_SIGNED_WAIVER = '/users/signedwaiver',

  PAYMENT_CLIENT_TOKEN = '/payments/clientToken',
  PAYMENT_PLANS = '/payments/plans',
  PAYMENT_CREATE_SUBSCRIPTION = '/payments/subscriptionCreate',
  PAYMENT_GET_SUBSCRIPTION = '/payments/subscription/',
  PAYMENT_CANCEL_SUBSCRIPTION = '/payments/subscriptionCancel',

  FEED_GET = '/feeds',
  FEED_CREATE = '/feeds',
  FEED_CREATE_ALL_ATHLETES = '/feeds/createFeedWithAllAthletes',
  FEED_UPLOAD_FILE = '/feeds/file',

  RELATIONSHIPS_GET_SELF = '/relationships',
  RELATIONSHIPS_GET_BY_ID = '/relationships',
  RELATIONSHIP_CREATE = '/relationships',
  RELATIONSHIP_REMOVE = '/relationships',

  VIDEO_CREATE = '/videos',
  VIDEO_GET = '/videos',
  VIDEO_PIN = '/videos/pin',
  VIDEO_DELETE = '/videos',
}

export const FEED_PAGE_SIZE = IS_LOCAL_DEVELOPMENT ? 2 : 5

export enum BraintreePlanId {
  SANDBOX_6MONTH = 't7tr',
  SANDBOX_12MONTH = '4th2',
  SANDBOX_TRAINING_PEAKS_SPECIAL = 'gf86',
  PROD_RESUB = '7g66',
  PROD_6MONTH = '72r6',
  PROD_12MONTH = 'qyq2',
  PROD_TRAINING_PEAKS_SPECIAL = 'qxy6',
}

export const BRAINTREE_PLAN_DATA: { [key in BraintreePlanId]: PlanConfig } = {
  [BraintreePlanId.SANDBOX_6MONTH]: {
    // sandbox
    title: '6 Month Subscription',
    subtitle: '$199.99 billed monthly',
    daysFree: 'First 10 days free',
    daysTrial: 10,
    body: 'Your first payment will be billed in 10 days.',
    favored: false,
  },
  [BraintreePlanId.SANDBOX_12MONTH]: {
    // sandbox
    title: '12 Month Subscription',
    subtitle: '$199.99 billed monthly',
    daysFree: 'First 30 days free',
    daysTrial: 30,
    body: 'Your first payment will be billed in 30 days.',
    favored: true,
  },
  [BraintreePlanId.SANDBOX_TRAINING_PEAKS_SPECIAL]: {
    // sandbox
    title: 'TrainingPeaks Exclusive Offer',
    subtitle: '$199.99 Billed Monthly',
    daysFree: 'First 45 Days Free',
    daysTrial: 45,
    body: 'By starting this subscription you agree to 6 monthly payments of $199.99. Your first payment will be charged after 45 days. Early cancellations will be charged a $300 nonrefundable fee.',
    favored: false,
  },
  [BraintreePlanId.PROD_RESUB]: {
    // Re-subscribe
    title: 'Resubscribe',
    subtitle: 'Total access',
    daysFree: '',
    daysTrial: 0,
    body: 'Your first payment will be billed immediately.',
    favored: false,
  },
  [BraintreePlanId.PROD_6MONTH]: {
    // prod
    title: '6 Month Subscription',
    subtitle: '$199.99 billed monthly',
    daysFree: 'First 10 days free',
    daysTrial: 10,
    body: 'Your first payment will be billed in 10 days.',
    favored: false,
  },
  [BraintreePlanId.PROD_12MONTH]: {
    // prod
    title: '12 Month Subscription',
    subtitle: '$199.99 billed monthly ',
    daysFree: 'First 30 days free',
    daysTrial: 30,
    body: 'Your first payment will be billed in 30 days.',
    favored: true,
  },
  [BraintreePlanId.PROD_TRAINING_PEAKS_SPECIAL]: {
    // sandbox
    title: 'TrainingPeaks Exclusive Offer',
    subtitle: '$199.99 Billed Monthly',
    daysFree: 'First 45 Days Free',
    daysTrial: 45,
    body: 'By starting this subscription you agree to 6 monthly payments of $199.99. Your first payment will be charged after 45 days. Early cancellations will be charged a $300 nonrefundable fee.',
    favored: false,
  },
}

export const ICON_SIZE = 30
